@import '../../../../../../../../styles/AppDesign.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  &Title {
    font-size: 1.8rem;
    display: flex;
    font-weight: bold;
    align-items: center;
    color: $TextColor;

    button {
      margin-left: 1rem !important;
    }
  }

  .sectionTitle {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: $TextColor;
    display: flex;
    align-items: center;

    .editButton {
      margin-left: 0.5rem;
      color: $MainCyan;
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    border: 1px solid $LighterGray;
    border-radius: 1rem;
    padding: 1rem;

    .portalSubmissionInstructionsContainer {
      display: flex;
      align-items: center;

      > div {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;

        button {
          align-self: flex-start;
          padding: 0 1rem;
          height: 45px;
          min-height: unset;
          margin-bottom: 0.25rem;
        }

        .instructionsDescription {
          color: $SubTextColor;
          margin-bottom: 2rem;
        }

        .portalLink {
          display: flex;
          align-items: center;
          font-size: 1.4rem;

          .launchBtn {
            color: $MainCyan;
            margin-right: 0.25em;
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        &:first-of-type {
          border-right: 1px solid $LighterGray;
          margin-right: 1rem;
        }
      }

      .fileUploader {
        label {
          border: 2px dashed $MainCyan;
        }
      }
    }

    .detailsTable {
      display: flex;
      flex-wrap: wrap;

      .details {
        display: flex;
        flex-direction: column;
        width: calc(33.333% - 2rem);
        margin-right: 2rem;
        padding-bottom: 0.4rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $LighterGray;
        // every 3rd item has no margin right
        &:nth-child(3n) {
          margin-right: 0;
        }

        .title {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.7rem;
          color: $TextColor;
        }

        .value {
          font-size: 1.25rem;
          color: $TextGray;
        }

        .valueItem {
          margin-bottom: 1rem;
        }
      }
    }

    .inputField {
      margin-bottom: 1rem;
    }

    .checkboxContainer {
      display: flex;

      .inputField {
        display: flex;
        align-items: center;
        margin-right: auto;
      }

      .ctaButton {
        height: 45px;
        min-height: unset;
        padding: 0 1rem;
      }
    }
  }

   .previewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 700px;
    height: 100%;

    iframe {
      flex: 1;
    }

    &Details {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      max-height: 300px;
      overflow-y: scroll;

      &Header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        span {
          flex: 1;
          font-weight: bold;
        }

        button {
          width: 6rem;
          min-height: unset;
          height: 45px;
        }
      }

      &FileItem {
        background-color: $UnselectedCyan;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .ctaContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      margin: 1rem 0;
      display: flex;
    }
  }
}
