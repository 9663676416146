@import '../../styles/AppDesign.scss';

.InputDropdownField {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.mod-noShadow {
    select {
      box-shadow: none;
      border: 1px solid $LighterGray;
    }
  }

  select {
    outline: none;
    background-color: $white;
    height: 47px;
    border-radius: 10px;
    padding: 0 15px;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    font-size: 15px;
    border: 1px solid $BorderGray;
    appearance: none;
    background-image: url('/assets/icons/dropdown_arrow.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 12px;
    color: $TextColor;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $white inset;

      &:first-line {
        font-size: 15px;
      }
    }

    &::placeholder,
    &:required:invalid {
      color: $SubTextColor;
    }

    &:focus {
      border: 1px solid $MainCyan;
    }

    &:disabled {
      background-color: $DisabledInputField;
    }
  }

  &-input {
    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;

      &-bottom {
        &-error {
          padding-bottom: 6px;
          font-size: 14px;
          color: $ErrorRed;
        }
      }
    }

    &-error {
      border: 1px solid $ErrorRed !important;
    }

    &-placeholder {
      color: $SubTextColor !important;
    }
  }
}
