@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

$TransitionSpeed: 0.4s;

.MainNavigation {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-right: $BorderGray;
  transition: all $TransitionSpeed ease-in-out;
  width: 20vw;
  min-width: 260px;
  max-width: 260px;
  overflow-x: hidden;

  &.mod-close {
    overflow-x: hidden;
    max-width: 48px;
    min-width: 48px;
    width: 48px;
    overflow-y: hidden;
    position: absolute;

    .MainNavigation {
      &-link,
      &-link-subRoutes {
        visibility: hidden;
      }

      &-burger-menu {
        margin-left: 0;
      }
    }
    .ApplicationVersion {
      visibility: hidden;
    }
  }

  &-burger-menu {
    padding-top: 0.1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
    pointer-events: none;
  }

  &-container {
    padding: 10% 0;
    margin-bottom: auto;
  }

  &-link {
    width: 90%;
    font-weight: 600;
    font-size: 16px;
    color: $TextGray;
    display: flex;
    align-items: center;
    height: 52px;
    margin-bottom: 8px;

    &:hover,
    &.mod-active {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      background-color: rgba($MainCyan, 0.1);
      color: $MainCyan;

      .MainNavigation-link-icon {
        background-color: $MainCyan;
      }
    }

    &.mod-openSubRoutes {
      margin-bottom: 0;
    }

    &-icon {
      margin-left: 20%;
      margin-right: 15px;
      background-color: $TextGray;
      width: 20px;
      height: 20px;
    }

    &-btn-dropdown {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: none;
      margin-left: auto;
      margin-right: 8%;
      mask: url('/assets/icons/dropdown_arrow.svg') no-repeat center;
      background-color: $TextGray;
      mask-size: contain;
      height: 100%;
      cursor: pointer;

      &.mod-active {
        background-color: $MainCyan;
      }

      &.mod-openSubRoutes {
        transform: rotate(180deg);
      }
    }

    &-subRoutes {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-bottom: 8px;

      .subRoute {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 14px;
        color: $TextGray;
        font-weight: 600;

        &.mod-active {
          color: $TextColor !important;
          .subRoute-dot {
            background-color: $MainCyan;
          }
        }

        span {
          display: flex;
          width: 100%;
        }

        &-notification {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $ErrorRed;
          height: 20px;
          min-width: 24px;
          max-width: 24px;
          margin-left: auto;
          padding: 0 0.7rem;
          border-radius: 25px;
          color: $white;
        }

        &-dot {
          margin-left: 20%;
          margin-right: 15px;
          background-color: $NavGray;
          min-width: 20px;
          width: 20px;
          height: 20px;
          align-self: flex-start;
        }

        &-line {
          align-self: flex-start;
          margin-left: calc(20% + 9px);
          height: 18px;
          width: 20px;
          border-left: 1px solid $NavGray;

          &:first-of-type {
            height: 15px;
          }
        }
      }
    }
  }

  .ApplicationVersion {
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
  }
}

@include tablet {
  .MainNavigation {
    position: absolute;
    left: 0;
    z-index: 10;
    background-color: transparent;

    &-burger-menu {
      visibility: visible;
      pointer-events: all;
    }

    &.mod-close {
      min-width: unset;
      overflow: visible;
      height: unset;

      .MainNavigation {
        &-burger-menu {
          width: 48px;
        }
      }
    }

    &.mod-open {
      box-shadow: 50px 0px 50px 21px rgba(0, 0, 0, 0.05);
      background-color: $white;
    }
  }
}

@include mobile {
  .MainNavigation {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    height: 100%;
    overflow-y: hidden;
    position: absolute;
    z-index: 10;

    &-container {
      padding: 5% 0;
    }

    &-link {
      width: 95%;
      &-icon {
        margin-left: 10%;
      }

      &-subRoutes {
        .subRoute {
          &-line {
            margin-left: calc(10% + 9px);
          }

          &-dot {
            margin-left: 10%;
          }
        }
      }
    }
  }
}
