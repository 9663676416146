@import '../../../../../../styles/AppDesign.scss';

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .threadListContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1;

    .threadContainer {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $LightGray;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 4rem;
      }

      .authorDetails {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .author {
          font-size: 1rem;
          color: $MainCyan;
        }

        .date {
          font-size: 0.8rem;
          color: $TextGray;
        }
      }

      .message {
        font-size: 1.2rem;
        color: $TextColor;
      }
    }
  }

  .inputContainer {
    display: flex;
    align-items: flex-start;
    margin: -2rem;
    padding: 2rem;
    padding-top: 1rem;
    background-color: $white;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);

    textarea {
      flex: 1;
    }

    .ctaButton {
      margin-left: 1rem;
      min-width: 100px;
    }
  }
}
