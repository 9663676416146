@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $white;
  min-height: 45px;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 27.5px;
  border: 1px solid $white;
  background-origin: border-box;
  background-color: $MainCyan;
  padding: .5rem 1rem;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:active {
    background-color: $MainLightCyan;
  }

  img {
    margin-left: 0.5rem;
  }

  &-label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-loader {
      position: absolute;
      margin: 0 auto;
    }

    &-text {
      &.mod-loading {
        visibility: hidden;
      }
    }
  }

  &.mod-fill {
    color: $MainCyan;
    background-color: $white;
  }

  &.mod-invert {
    border: 1px solid $MainCyan;
    background-color: transparent;
    color: $MainCyan;

    &:active {
      background-color: $bgGray;
    }
  }

  &.mod-rectangled {
    border-radius: 10px;
  }

  &.mod-disabled {
    opacity: 0.5;
    cursor: progress;
  }
}

@include tablet {
  .cta-button {
    height: 45px !important;
    min-height: 45px !important;
  }
}

@include mobile {
  .cta-button {
    height: 45px !important;
    min-height: 45px !important;
  }
}
