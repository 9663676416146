@import 'styles/AppDesign.scss';
@import 'styles/media-queries.scss';

$radioMargin: 8px;

.RadioField {
  width: 100%;

  &-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.mod-inline {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  input {
    background-color: $white;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    font-size: 15px;
    border: 1px solid #0d2238;
    margin: $radioMargin 0px;
    cursor: pointer;

    &:focus {
      border: 1px solid $MainCyan;
    }

    &:disabled {
      background-color: #ebebeb;
    }

    &:checked {
      appearance: radio;
      -webkit-appearance: radio;
    }

    &.mod-checkbox {
      border-radius: 3px;
    }
  }

  &-label {
    font-weight: 600;
    font-size: 15px;
    padding-bottom: 10px;

    &-bottom {
      &-error {
        padding-bottom: 6px;
        font-size: 14px;
        color: #ea4949;
      }
    }

    &.mod-inline {
      padding-bottom: 0;
      padding-right: 14px;

      @include mobile {
        padding-bottom: 10px;
        padding-right: 0;
      }
    }
  }

  &-options {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    max-width: 100%;

    &-item {
      &-container {
        display: flex;
        padding-right: 12px;

        label {
          display: inline-block;
          white-space: normal;
          cursor: pointer;
          padding: $radioMargin;
          box-sizing: border-box;
          width: auto;
        }
      }

      &-error {
        border: 1px solid #ea4949 !important;
      }
    }

    &.mod-inline {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      label {
        white-space: nowrap;
      }

      @include mobile {
        align-items: flex-start;
        flex-wrap: wrap;

        label {
          white-space: normal;
        }
      }
    }
  }
}
