.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputField {
  margin-bottom: 1rem;
  width: calc(50% - 0.5rem);
  margin-right: 1rem;

  &:nth-child(2n) {
    margin-right: 0;
  }
}
