@import 'styles/AppDesign.scss';

.MedicareCappedRentalNotificationLetter {
  p {
    line-height: 1.8;
  }

  ul {
    line-height: 1.5;

    li {
      padding: 4px 0px;

      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  &-radio {
    &-selected {
      & > div > label {
        font-weight: bold !important;
      }
    }

    &-non-selected {
      & > div > label {
        font-weight: bold !important;
      }

      & > ul,
      & .MedicareCappedRentalNotificationLetter-item-options {
        opacity: 0.6;
      }
    }
  }

  &-item-options {
    margin-top: -20px;
    padding-left: 40px;
  }
}
