.taskManagerOrdersTableContainer {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.filterInputField {
  flex-direction: row;
  align-items: end;
  margin: 0 2rem 1rem 2rem;

  input {
    max-width: 500px;
    width: 100%;
  }
}
