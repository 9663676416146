.AccountDetails {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-information {
    margin-bottom: 1rem;

    &-fieldName {
      font-weight: bold;
      margin-right: 1rem;
    }
  }

  &-tables {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 2rem -2rem;

    .Table {
      flex: 1;
    }
  }
}
