.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .input {
    margin-bottom: 1.5rem;
  }

  .clipLoader {
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.ctaButton {
  width: 100%;
  margin-top: auto;
}
