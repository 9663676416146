@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.MyTasks {
  display: flex;
  flex-direction: column;

  &-manual-tasks-toolbar {
    .cta-button {
      padding: 0 1.5rem;
    }
  }

  .Table {
    min-height: 500px;
    &-row-inline {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-row-confirm {
      &-buttons {
        display: flex;
        align-items: center;
      }
    }

    .cta-button {
      width: 100%;
      min-height: unset;
      height: 45px;
      border-radius: 10px;
      position: relative;

      &-icon {
        width: 34px;
        height: 34px;
      }

      &-confirm {
        height: 34px;
      }

      &-note {
        overflow: hidden;
        color: inherit;
        font-size: .75rem;
        font-weight: inherit;
        white-space: inherit;
        display: block;
        &:hover {
          color: inherit;
        }
      }

      &-notification {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ea4949;    
        z-index: 99;
        position: absolute;
        top: -.3rem;
        right: -.3rem;
      }
    }
  }

  &-new-orders {
    flex: 0.6;
    margin-bottom: 2rem;
  }

  &-other-tasks {
    flex: 0.4;
  }

  &-new-orders,
  &-other-tasks {
    display: flex;
    flex-direction: column;

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 2rem;

        input[type='date'] {
          border: none;
          padding: 0 2rem;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 1rem;
          border: 1px solid $LightGray;
          height: 32px;
          align-self: center;
          border-radius: 10px;
          text-align: center;

          &::-webkit-calendar-picker-indicator {
            background-position: center;
            cursor: pointer;
          }
        }
      }

      &-title {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 0;
      }
    }
  }
}
