@import '../../../../../../../../styles/AppDesign.scss';

.container {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex: 1;

  .previewContainer {
    display: flex;
    flex-direction: column;
    width: 50%;

    iframe {
      flex: 1;
    }

    &Details {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      max-height: 300px;
      overflow-y: scroll;

      &Header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        span {
          flex: 1;
          font-weight: bold;
        }

        button {
          width: 6rem;
          min-height: unset;
          height: 45px;
        }
      }

      &FileItem {
        background-color: $UnselectedCyan;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 2rem;

    .title {
      font-size: 24px;
      font-weight: 500;
    }

    hr {
      width: 100%;
      border: 1px solid $LighterGray;
      margin: 1rem 0;
      margin-bottom: 2rem;
    }

    .inputField {
      margin-bottom: 1rem;
    }

    .splitInputContainer {
      display: flex;
      justify-content: space-between;

      .inputField {
        width: calc(50% - 0.5rem);
      }
    }

    .ctaContainer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
