@import '../../../../../../styles/AppDesign.scss';
@import '../../../../../../styles/media-queries.scss';

.MyTasks-CreateNewPatient {
  &-modal {
    display: flex;
    flex-direction: column;

    section {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }

    &-title {
      font-weight: bold;
      font-size: 1.5rem;
      border-bottom: 1px solid $LighterGray;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    &-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input,
      select {
        border-color: $LighterGray;
      }

      .InputTextField,
      .InputDropdownField {
        width: calc(50% - 1rem);
        margin-bottom: 1rem;

        @include mobile {
          width: 100%;
        }

        &.mod {
          &-fullWidth {
            width: 100%;
          }
        }

        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }

    .cta-button {
      margin-top: auto;
      border-radius: 10px;
      width: 100px;
      margin-left: auto;

      @include mobile {
        width: 100%;
      }
    }
  }

  &-MissingInformation {
    &-modal {
      display: flex;
      flex-direction: column;

      &-header {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        border-bottom: 1px solid $LighterGray;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        img {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        p {
          font-weight: bold;
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;

            span {
              margin-right: 1rem;
              border: 1px solid $FalseRed;
              padding: 0.5rem 1rem;
              border-radius: 10px;
            }

            img {
              cursor: pointer;
            }

            // Add bullet points to the list
            &::before {
              content: '⤷';
              margin-right: 1rem;
              color: $FalseRed;
            }
          }
        }
      }

      textarea {
        border-color: $LighterGray;
        height: 20rem;
        max-height: unset;
        margin-bottom: 10rem;
      }

      &-cta-container {
        display: flex;
        align-items: center;
        margin-left: auto;

        .cta-button {
          margin-left: 1rem;
          padding: 0 2rem;
          border-radius: 10px;

          @include mobile {
            margin-top: 1rem;
          }
        }

        @include mobile {
          flex-direction: column;
          justify-content: center;
          margin-left: 0;
        }
      }
    }
  }
}
