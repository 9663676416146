@import '../../../../../../styles/AppDesign.scss';
@import '../../../../../../styles/media-queries.scss';

.MyTasks-CheckOrders {
  &-modal {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &-order-form {
      position: relative;

      &-delete-btn {
        margin-left: auto;
        margin-bottom: 2rem;
        z-index: 1;
        background-color: $LightGray;
        border: 0;
        border-radius: 2rem;
        width: 2rem;
        height: 2rem;
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
        position: absolute;
        top: -1rem;
        right: -1rem;
        color: $white;
      }
    }

    .Accordion {
      border-left: 1px solid rgba($MainCyan, 1);
      border-right: 1px solid rgba($MainCyan, 1);
      // border-bottom: 1px solid rgba($MainCyan, 1);
      margin-bottom: 2rem;

      &.mod-error {
        border-left: 1px solid rgba($ErrorRed, 1);
        border-right: 1px solid rgba($ErrorRed, 1);
        // border-bottom: 1px solid rgba($ErrorRed, 1);
      }

      &.Mui-expanded {
        border-bottom: 1px solid rgba($MainCyan, 1);
        &.mod-error {
          border-bottom: 1px solid rgba($ErrorRed, 1);
        }
      }

      .Accordion {
        margin-bottom: 0;
        .MuiAccordionDetails-root {
          padding: 2rem;
        }
      }
    }

    .InputTextField,
    .DatePicker,
    .InputDropdownField {
      select,
      textarea,
      input {
        border-color: $LighterGray;

        &:focus {
          border-color: $SelectedCyan;
        }
      }

      &.mod {
        &-highlight {
          input {
            border-color: $MainCyan;
          }
        }

        &-greenHighlight {
          // If there's value then green
          select,
          input {
            border-color: $TrueGreen;
            color: $TrueGreen;
            font-weight: bold;
          }
        }
      }
    }

    .cta-button {
      border-radius: 10px;
    }

    &-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 1rem;

      &-highlight {
        color: $MainCyan;
        padding: 0 0.4rem;
        font-weight: bold;
      }
    }

    &-new-orders {
      &-anchor {
        visibility: hidden;
        position: absolute;
      }
      /** Header Component  */
      &-header {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $LightGray;

        &-cta-container {
          display: flex;
          flex: 1;

          .cta-button {
            width: 8rem;

            &:first-of-type {
              margin-right: 1rem;
            }
          }
        }
      }

      /** ViewDocuments Component */
      &-view-documents {
        display: flex;
        height: 100%;

        iframe {
          min-width: 50%;
          border: none;
        }

        &-list-container {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;

          padding-bottom: 1rem;

          &-list {
            width: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: auto;

            &-item {
              width: 85%;
              margin-bottom: 1.4rem;
              position: relative;

              &:first-of-type {
                margin-top: 2rem;
              }
              .cta-button {
                width: 100%;
                border-radius: 10px;
                font-size: 14px;

                span {
                  padding: 0 0.5rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          &-upload-btn-container {
            display: flex;
            justify-content: center;
            padding: 1rem 0;
            width: 99%;
            border-top: 1px solid $LighterGray;

            .cta-button {
              width: 84%;
              border-radius: 10px;
              background-color: $TrueGreen;
            }
          }
        }
      }

      /** SearchPatient Component */
      &-search-patient {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        &-container {
          display: flex;
          flex-direction: column;
          background-color: $UnselectedCyan;
          border: 1px solid $LightGray;
          border-radius: 0.5rem;
          padding: 1rem;
          padding-bottom: 0;
          overflow: hidden;

          &-table {
            background-color: $white;
            height: 20rem;
            display: flex;
            flex-direction: column;
            margin-left: -1rem;
            margin-right: -1rem;

            .cta-button {
              // width: 100px;
              width: 100%;
            }
          }

          &-form {
            display: flex;
            align-items: flex-end;
            margin-bottom: 2rem;

            .InputTextField,
            .DatePicker,
            .InputDropdownField {
              margin-right: 1rem;
            }

            .DatePicker {
              min-width: 280px;
            }

            .cta-button {
              white-space: nowrap;
              padding: 0 2rem;
            }

            @include tablet {
              flex-direction: column;
              align-items: flex-start;

              .InputTextField,
              .DatePicker,
              .InputDropdownField {
                margin-right: 0;
                margin-bottom: 1rem;
              }

              .DatePicker {
                width: 100%;
              }

              .cta-button {
                margin: 1rem auto;
              }
            }
          }

          &-tags {
            display: flex;
            background-color: $white;
            border-radius: 1rem;

            flex-wrap: wrap;

            &-item {
              border: 1px solid $LightGray;
              padding: 0.5rem 1rem;
              border-radius: 0.5rem;
              margin: 1rem;
            }
          }
        }
      }

      /** NewOrderForm Component */
      &-new-order-form {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        margin-bottom: 2rem;
        border: 1px solid $LightGray;
        position: relative;

        @include tablet {
          padding: 1rem;
        }

        &-remove-btn {
          margin-left: auto;
          margin-bottom: 2rem;
          background-color: $LightGray;
          border: 0;
          border-radius: 2rem;
          width: 2rem;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          cursor: pointer;
          position: absolute;
          top: -1rem;
          right: -1rem;
          color: $white;
        }

        section {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $LightGray;
          padding-bottom: 2rem;
          margin-bottom: 2rem;

          &:last-of-type {
            border-bottom: 0;
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: 2rem;
          text-decoration: underline;
        }

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-cta-container {
            display: flex;
            .cta-button {
              padding: 0 2rem;

              &:last-of-type {
                margin-left: 1rem;
              }
            }
          }

          input[type='file'] {
            display: none;
          }
        }

        &-detail {
          display: flex;
          justify-content: space-between;

          @include mobile {
            flex-direction: column;
          }

          &-section {
            display: flex;
            flex-direction: column;
            width: 100%;

            &:first-of-type {
              margin-right: 2rem;
            }

            &:last-of-type {
              margin-right: 0;
            }

            .InputTextField,
            .DatePicker,
            .InputDropdownField {
              margin-bottom: 1rem;

              textarea {
                height: 10rem;
                font-family: Arial, Helvetica, sans-serif;
              }
            }
          }
        }

        &-cta-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 1.2rem;

          .cta-button {
            padding: 0 2rem;
            align-self: center;
            margin-bottom: 0.5rem;
            width: 20%;

            &:last-of-type {
              width: 100%;
            }
          }

          @include mobile {
            flex-direction: column;

            .cta-button {
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    &-cta-container {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      .cta-button {
        padding: 0 2rem;
      }

      @include tablet {
        flex-direction: column;

        .cta-button {
          white-space: normal;
          margin-bottom: 1rem;
        }
      }

      &-add-patient-btn {
        color: $TrueGreen;
        background-color: $white;
        border: 1px solid $TrueGreen;
      }

      &-update-btn {
        background-color: $TrueGreen;
      }

      &-no-orders-update-btn {
        background-color: $TrueGreen;
        margin-top: auto;
        width: 100%;
      }
    }
  }
}
