.InvoiceDetails {
  &-toolbar {
    display: flex;

    .cta-button {
      padding: 1rem;
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-information {
    margin-bottom: 1rem;

    &-fieldName {
      font-weight: bold;
      margin-right: 1rem;
    }

    &-fieldValue {
    }
  }
}
