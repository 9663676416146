@import 'styles/AppDesign.scss';
@import 'styles/media-queries.scss';

.DatePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.mod-align-start {
    align-items: flex-start;
  }

  input {
    user-select: none !important;
    background-image: url('/assets/icons/calendar.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 18px;
  }

  .DatePicker-calendar {
    position: absolute;
    z-index: 10;
    top: 75px;
    width: 350px;
    border-radius: 10px;
    border: 1px solid $NavGray;
    overflow: hidden;
    box-shadow: $GenericBoxShadow;

    .react-calendar {
      width: 100%;
      max-width: 100%;
      background: $white;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.125em;
    }

    .react-calendar--doubleView {
      width: 700px;
    }
    .react-calendar--doubleView .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
    }

    .react-calendar--doubleView .react-calendar__viewContainer > * {
      width: 50%;
      margin: 0.5em;
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }
    .react-calendar button:enabled:hover {
      cursor: pointer;
    }
    .react-calendar__navigation {
      display: flex;
      height: 44px;
      margin-bottom: 1em;
      align-self: center;
    }
    .react-calendar__navigation button {
      min-width: 44px;
      background: none;
      font-weight: bold;
      font-size: 16px;
      padding-top: 1rem;
      color: $SelectedCyan;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      // background-color: #e6e6e6;
    }
    .react-calendar__navigation button[disabled] {
      // background-color: #f0f0f0;
    }

    .react-calendar__month-view {
    }
    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      font-weight: bold;
      padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }

    .react-calendar__month-view__days__day,
    .react-calendar__month-view__days__day--weekend {
      color: $TextColor;

      &:disabled {
        color: $TextGray;
      }
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: $TextDarkerGray;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }
    .react-calendar__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;
    }
    .react-calendar__tile:disabled {
      background-color: $LighterGray;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: rgba($MainCyan, 0.1);
    }
    .react-calendar__tile--now {
      // background: #ffff76;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      // background: #ffffa9;
    }
    .react-calendar__tile--hasActive {
      background: rgba($MainCyan, 0.1);
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: rgba($MainCyan, 0.1);
    }
    .react-calendar__tile--active {
      background: $SelectedCyan;
      color: $white;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: $SelectedCyan;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #e6e6e6;
    }
  }

  .Calendar-button {
    cursor: pointer;
    width: 45px;
    height: 45px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

@include mobile {
  .DatePicker {
    .DatePicker-calendar {
      width: unset;
    }
  }
}
