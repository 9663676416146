@import '../../../../../../../../styles/AppDesign.scss';

.container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 2rem;


  .previewContainer {
    width: 50%;
    height: 100%;
    position: relative;

    .iframeOverlay {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $MainCyan;
      bottom: 1rem;
      right: 2rem;
      padding: 8px;
      border: none;
      min-height: unset !important;
      height: unset !important;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;

    .sectionTitle {
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 2rem;
      margin-top: 2rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .inputField {
      margin-bottom: 1rem;
    }

    [class*='DatePicker '] input {
      box-shadow: none;
      border: 1px solid #ddd;
    }

    hr {
      width: 100%;
      border: 1px solid $LighterGray;
      margin: 1rem 0;
      margin-bottom: 2rem;
    }

    .splitInputContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .inputField, [class*='DatePicker '] {
        width: calc(50% - 0.5rem);
      }
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      .detailItem {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-bottom: 1rem;

        > span {
          &:first-of-type {
            font-weight: bold;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.ctaContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    margin: 1rem 0;
    display: flex;
  }
}
