@import '../../styles/AppDesign.scss';

.InputAutoCompleteField {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &.mod-noShadow {
    input {
      box-shadow: none;
      border: 1px solid $LighterGray;
    }
  }

  input {
    background-color: $white;
    height: 45px;
    border-radius: 10px;
    padding: 0 15px;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    font-size: 15px;
    border: 1px solid $BorderGray;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $white inset;

      &:first-line {
        font-size: 15px;
      }
    }

    &::placeholder,
    &:required:invalid {
      color: $SubTextColor;
    }

    &:focus {
      border: 1px solid $MainCyan;
    }

    &:disabled {
      background-color: $DisabledInputField;
      color: $SubTextColor;
    }
  }

  &-input {
    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;

      &-bottom {
        &-error {
          padding-bottom: 6px;
          font-size: 14px;
          color: $ErrorRed;
        }
      }
    }

    &-error {
      border: 1px solid $ErrorRed !important;
    }

    &-disabled {
      background-color: $DisabledInputField !important;
      color: rgb(95, 95, 95);
    }
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    border: 1px solid $BorderGray;
    z-index: 1;
    list-style: none;
    padding: .5rem 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;

    li {
      padding: .5rem 1rem;
      cursor: pointer;
      font-size: 15px;

      &:hover {
        background-color: $LighterGray;
      }

      &.selected {
        background-color: $MainCyan;
        color: $white;
      }
    }
  }
}
