@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.ICD10CM {
  display: flex;
  flex-direction: column;

  &-header {
    padding: 2rem;
    display: flex;
    align-items: center;

    &-search-bar {
      align-self: center;
      flex: 1;
      border-radius: 10px;

      input {
        border-color: $NavGray;
      }
    }

    &-reset-container {
      padding-left: 3rem;
      display: flex;
      align-items: center;

      &-text {
        line-height: 28px;
        font-size: 14px;
        color: $TextDarkerGray;
        white-space: nowrap;
      }

      &-refresh-button {
        margin-left: 20px;
        width: 150px;
        font-size: 14px;
      }
    }
  }

  &-table {
    &-download-cell {
      cursor: pointer;
      filter: invert(54%) sepia(55%) saturate(1821%) hue-rotate(144deg) brightness(96%) contrast(95%);
    }
  }
}
