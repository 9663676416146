@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.MenuGrid {
  display: flex;
  flex-wrap: wrap;

  &-container {
    background-color: $white;
    box-shadow: $GenericBoxShadow;
    border-radius: 10px;
    margin-bottom: 1.25rem;
    flex: 0 0 calc(33.3333% - 1rem);
    margin-right: 1.5rem;
    height: 230px;

    &.mod-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .react-loading-skeleton {
      height: 100%;
      border-radius: 10px;
      --base-color: #e6eaed;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 1rem;

      &-icon {
        width: 80px;
        height: 80px;
        background-color: $MainCyan;
        mask-size: 100% !important;
        -webkit-mask-size: 100% !important;
      }

      &-title {
        font-size: 18px;
        font-weight: 600;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        text-align: center;
      }

      &-description {
        color: $TextGray;
        text-align: center;
        line-height: 1.5;
      }
    }
  }
}

@include tablet {
  .MenuGrid {
    align-items: start;
    justify-content: space-between;

    &-container {
      flex-basis: calc(50% - 0.5rem);
      margin-right: 0;
      height: 245px;
      max-height: 245px;
    }
  }
}

@include mobile {
  .MenuGrid {
    flex-direction: column;
    &-container {
      width: 100%;
      flex: 1;
      height: unset;
      margin-right: 0;
    }
  }
}
