@import '../../../../../../../../styles/AppDesign.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  flex: 1;

  iframe {
    width: 50%;
  }

  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;

    .filterInputField {
      flex-direction: row;
      align-items: end;
      margin: 0 2rem 1rem 2rem;

      input {
        max-width: 500px;
        width: 50%;
      }
    }

    .table {
      flex: 1;
      background-color: $white;
    }
  }
}
