.title {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  margin-bottom: 3rem;
}

.container {
  display: flex;
  flex-wrap: wrap;

  .details {
    display: flex;
    flex-direction: column;
    width: calc(25% - 0.75rem);
    margin-right: 1rem;
    margin-bottom: 1.85rem;

    span {
      &:first-of-type {
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}
