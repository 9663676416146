@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.Card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 1rem;
  box-shadow: $GenericBoxShadow;
  justify-self: center;
  padding: 2rem;
  flex: 1;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 2rem;

    &-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
    }
  }

  &.mod-no-padding {
    padding: 0px;
    overflow: hidden;
  }

  &-loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

@include tablet {
  @media screen and (orientation: landscape) {
    .Card {
      min-height: 600px;
    }
  }
}
