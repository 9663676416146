@import '../../../../../../../../styles/AppDesign.scss';

.container {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex: 1;

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  hr {
    width: 100%;
    border: 1px solid $LighterGray;
    margin: 1rem 0;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .inputField {
      margin-bottom: 1rem;

      select,
      input {
        color: unset !important;
        background-color: $white !important;
        cursor: pointer;
      }
    }

    .portalLinkLabel {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;
    }

    a {
      color: $MainCyan;
      text-decoration: underline;
    }

    .ctaContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        margin: 1rem 0;
        display: flex;
      }
    }
  }
}
