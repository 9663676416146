@import 'styles/AppDesign.scss';

.title {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  border-bottom: 1px solid $LighterGray;
  padding-bottom: 1rem;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .inputField {
    margin-bottom: 1rem;
  }

  .inputTextAreaField {
    flex: 1;
    margin-bottom: 1rem;

    textarea {
      flex: 1;
    }
  }

  .ctaContainer {
    display: flex;
    margin-top: auto;
    .ctaButton {
      flex: 1;
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}
