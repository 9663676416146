@import 'styles/AppDesign.scss';

.FormDetailsLayout {
  &-form {
    display: flex;
    flex-direction: column;

    .InputTextField,
    .InputDropdownField,
    .RadioField,
    .SignaturePadField {
      margin-bottom: 24px;
    }

    &-container {
      padding: 28px 0px;
    }

    &-error {
      padding-left: 0.3rem;
      margin-top: 1.5rem;
      color: $ErrorRed;
      opacity: 0;

      &.mod-show {
        opacity: 1;
      }
    }

    &-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * {
        margin: 14px 0px;
      }

      .cta-button {
        width: 180px;
      }
    }

    hr {
      appearance: none;
      border-style: solid;
      border-width: 0.5px;
      border-color: $LightGray;
    }

    &-field-amount {
      position: relative;

      input {
        padding-left: 70px !important;
      }

      &-prefix {
        position: absolute;
        left: 15px;
        bottom: 15px;
      }
    }
  }
}
