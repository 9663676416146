@import '../../styles/AppDesign.scss';

.Accordion {
  &.mod {
    &-first {
      border-top: 1px solid rgba($MainCyan, 1);
    }

    &-error {
      &.mod-first {
        border-top: 1px solid rgba($ErrorRed, 1);
      }

      .Accordion-title-container {
        &-title {
          color: $ErrorRed;
        }

        &-subtitle {
          color: $ErrorRed;
        }
      }

      &.MuiAccordion-root {
        background-color: rgba($ErrorRed, 0.05);

        > .MuiAccordionSummary-root {
          border-bottom: 1px solid rgba($ErrorRed, 1);
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        * {
          fill: $DarkCyan;
        }
      }
    }

    &-no-error {
      .Accordion-title-container {
        &-title {
          color: $DarkCyan;
        }

        &-subtitle {
          color: $DarkCyan;
        }
      }
    }
  }

  &-title-container {
    display: flex;
    align-items: center;
    width: 100%;

    &-title {
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-family;
      color: $DarkCyan;
    }

    &-subtitle {
      margin-left: auto;
      margin-right: 1rem;
      font-size: 1rem;
      color: $TextGray;
    }
  }

  // ==================== Mui Styling ====================

  &.MuiAccordion-root {
    &:before {
      background-color: transparent;
    }
    box-shadow: none;
    background-color: rgba($MainCyan, 0.05);

    .MuiAccordionSummary-root {
      border-bottom: 1px solid rgba($MainCyan, 1);
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    * {
      fill: $DarkCyan;
    }
  }

  .MuiAccordionDetails-root {
    padding: 1rem;
    border-color: $LighterGray !important;
    background-color: $white;
    border-bottom: 1px solid $LighterGray;

    .MuiTypography-root {
      font-size: 1rem;
      font-weight: normal;
      color: $TextColor;
    }
  }
}
