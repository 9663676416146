@import 'styles/AppDesign.scss';

.SSPForm {
  &-language-globe {
    margin-left: auto;
  }
  &-empty {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $TextGray;
  }
  &-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    position: relative;
    flex: 1;

    h1 {
      font-size: 22px;
    }

    p,
    ul,
    ol {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 2rem;

      li {
        margin-bottom: 1rem;
        &::marker {
          font-weight: bold;
        }
      }
    }

    .InputTextField {
      margin-bottom: 24px;
    }
  }

  &-remarks {
    display: block;
    padding: 1rem 0;
    text-align: center;
  }

  &-error {
    color: $ErrorRed;
    margin: 1rem 0;
    text-align: center;
    display: block;
  }

  &-ctaContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;

    .cta-button {
      width: 180px;
    }
  }
}
