@import 'styles/AppDesign.scss';

.AdvancedBeneficiaryNotice {
  p {
    line-height: 1.6;
  }

  &-field-notifier {
    margin-bottom: 0px !important;
  }
}
