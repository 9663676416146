@import '../../../../../../styles/AppDesign.scss';
@import '../../../../../../styles/media-queries.scss';

.FormDetails {
  overflow-y: hidden;

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &.mod-preview {
      background-color: $LightGray;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }
  }

  .NavigationTabs-tabs {
    height: 70px;
    border-top-right-radius: 0;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-information {
      width: 50%;
      overflow-y: scroll;

      &-content {
        &-row {
          margin-bottom: 1rem;
          display: flex;
          span {
            word-break: break-word;
            &:first-of-type {
              margin-right: 2rem;
              max-width: 160px;
              min-width: 160px;
              width: 40%;
              font-weight: bold;
            }

            &:last-of-type {
              user-select: text;
            }
          }
        }
      }

      &.mod-SSPForms {
        background-color: $bgGray;
        display: flex;
        flex-direction: column;
      }
    }

    &-preview {
      width: 50%;
      position: relative;

      iframe {
        position: relative;
        z-index: 1;
        border: 0;
        right: 0;
        margin: 0;
        width: 100%;
        height: 100%;
      }

      &-expand-button {
        display: none;
      }
    }
  }
}

@include tablet {
  .FormDetails {
    overflow-y: scroll;
    &-container {
      flex-direction: column;
      justify-content: flex-start;

      &-information,
      &-preview {
        width: 100%;
        height: 50%;
        position: relative;
        transition: height 300ms ease-in-out;

        &.mod-hidden {
          height: 0%;
        }

        &-expand-button {
          display: block;
          height: 36px;
          padding: 0px 20px;
          box-sizing: border-box;
          position: absolute;
          top: -36px;
          right: 0px;
          z-index: 1;
          appearance: none;
          border: none;
          border-top-left-radius: 10px;
          color: $white;
          background-color: #{$TextDarkerGray}70;
          cursor: pointer;

          &:hover,
          &:active {
            background-color: $TextDarkerGray;
          }
        }
      }

      &-information {
        padding-bottom: 36px;
        transition: height 300ms ease-in-out;

        &-content {
          &-row {
            span {
              &:first-of-type {
                min-width: 100px;
                max-width: 100px;
              }
            }
          }
        }

        &.mod-preview-hidden {
          height: 100%;
        }
      }
    }

    &-loading {
      transition: height 300ms ease-in-out;

      &.mod-preview {
        top: 0;
      }

      &.mod-hidden {
        height: 0%;
      }
    }
  }
}
