.table {
  width: 100%;
  height: 100%;

  flex: 1;

  > div {
    height: 100%;
  }
}

.container {
  width: 100%;
  height: 100%;
  padding-bottom: 0;

  > div {
    height: 100%;
    padding: 0;
    border: 0;

    div[class*='Table-body'] {
      background-color: white;
    }
  }
}

.inputSearchContainer {
  padding: 0 2rem;
  padding-top: 1rem;
}
