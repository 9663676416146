@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.ManageUserLayout {
  flex-direction: row;
  &-response {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    width: calc(50% - 2rem);

    &-content {
      flex: 1;
      padding: 1rem;
      user-select: text;
      background-color: $BorderGray;
      overflow-wrap: break-word;
    }

    .cta-button {
      margin-top: 2rem;
      width: 100%;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    min-width: calc(50% - 2rem);
    height: 100%;
    padding-right: 2rem;
    border-right: 1px solid $NavGray;

    .cta-button {
      margin-top: 1rem;
      width: 40%;
      align-self: center;
    }

    input,
    select {
      margin-bottom: 1rem;
      border-color: $NavGray;
    }

    &-error {
      display: none;

      &.mod-show {
        display: block;
      }
    }
  }
}

@include tablet {
  .ManageUserLayout {
    flex-direction: column;
    justify-content: flex-start;

    &-form,
    &-response {
      border: 0;
      padding: 0;
      width: 100%;
      justify-content: flex-start;
    }

    &-form {
      height: unset;
      margin-bottom: 2rem;
    }

    &-response {
      height: 400px;
    }
  }
}

@include mobile {
  .ManageUserLayout {
    flex: unset;
    padding: 1.5rem;

    .cta-button {
      width: 100%;
    }

    &-response {
      height: 350px;
    }
  }
}
