@import 'styles/AppDesign.scss';

.FormReleaseOfLiabilityItemReturn {
  p {
    line-height: 1.8;
  }

  &-field-name {
    margin-bottom: 0px !important;
  }
}
