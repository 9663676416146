@import 'styles/AppDesign.scss';

.PatientOrientationForm {
  &-description {
    margin-top: 0px;

    & ol {
      line-height: 1.4;
      padding-left: 30px;
    }
  }
}
